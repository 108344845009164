import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Clean 6-6-6\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Team Series WOD 1`}</strong></p>
    <p>{`9-15-21 reps each of:`}</p>
    <p>{`Thrusters (95/65)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p><em parentName="p">{`*`}{`Do this individually or partner up and do the Team Series version
with synchronized Thrusters and Burpees.`}</em></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.  More
info to come. Truegritcomp.wordpress.com.***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      